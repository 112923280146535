// 字典接口
import base from './base'
import axios from './http'
// import qs from 'qs' // 根据需求是否导入QS模块

const auditDoubts = {
  // 创建存疑项目
  addAuditDoubts (params) {
    return axios.post(`${base.sq}/audit-doubts`, params)
  },
  // 删除存疑项目
  delAuditDoubts (id) {
    return axios.delete(`${base.sq}/audit-doubts/${id}`)
  },
  // 下线存疑项目
  offlineAuditDoubts (id) {
    return axios.put(`${base.sq}/audit-doubts/${id}/offline`)
  },
  // 获取存疑项目
  getAuditDoubts (id) {
    return axios.get(`${base.sq}/audit-doubts`)
  },
}
export default auditDoubts
