// 填报详情
<template>
  <v-container
    id="regular-tables"
    tag="section"
    fluid
  >
    <v-row
      v-if="((!orgQX && formId ===wsFormId) || (formId ===tjFormId && !isDeputy)) && !isXs"
      class="mt-4"
    >
      <v-col md="12">
        <v-stepper>
          <v-stepper-header v-model="stage1">
            <template v-for="(item,inx) in processesList">
              <v-stepper-step
                :key="`${inx}-step`"
                :complete="item.isicon"
                :complete-icon="item.icon"
                :step="inx+1"
                :color="item.color"
              >
                {{ item.name }}
              </v-stepper-step>

              <v-divider
                v-if="inx !== processesList.length-1"
                :key="inx"
              />
            </template>
            <v-divider />
            <template v-for="(item,inx) in processesList2s">
              <v-stepper-step
                :key="`${inx}-step`"
                :complete="item.isicon"
                :complete-icon="item.icon"
                :step="inx+1+processesList.length"
                :color="item.color"
              >
                {{ item.name }}
              </v-stepper-step>

              <v-divider
                v-if="inx !== processesList2s.length"
                :key="inx"
              />
            </template>
          </v-stepper-header>
        </v-stepper>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col
        cols="12"
        sm="8"
        md="8"
        lg="8"
        xl="8"
      >
        <v-card class="pa-2 mt-0 pa-sm-7 pa-md-7 pa-lg-7 pa-xl-7">
          <div class="project_operation">
            <v-btn
              text
              small
              color="blue darken-1"
              @click="exportTable"
            >
              <v-icon
                left
                small
              >
                fa4 fa-download
              </v-icon>
              导出
            </v-btn>
            <v-btn
              v-if="isAdmin||isEdit || projectState!==5"
              text
              small
              color="success"
              @click="editProject(formId, id)"
            >
              <v-icon
                left
              >
                fa4 fa-edit
              </v-icon>
              编辑
            </v-btn>
          </div>
          <div class="text-h3 text-sm-h2 text-md-h2 text-lg-h2 text-xl-h2 font-weight-bold text-center red--text text--darken-4 projectName">
            {{ projectName }}
            <span
              v-if="(!orgQX && formId ===wsFormId) || formId ===tjFormId"
              class="medal"
            >
              <!-- <img
                v-if="projectisPush === '是'"
                :src="medalAdopt2"
              >
              <img
                v-else-if="projectState ===10"
                :src="xmzt2"
              >
              <img
                v-else-if="projectState === 5"
                :src="xmzt3"
              >
              <img
                v-else-if="projectState === -5"
                :src="xmzt1"
              > -->

              <img
                :src="getXmzt(projectStatus2, projectState)"
              >
            </span>
            <!-- <span
              v-if="orgQX && formId ===tjFormId"
              class="medal"
            >
              <img
                v-if="projectState === 10"
                :src="medalAdopt"
              >
              <img
                v-if="projectState === -5"
                :src="medalReject"
              >
            </span> -->
            <span
              v-if="orgQX && formId ===wsFormId"
              class="medal"
            >
              <img
                v-if="stageStatus === '中止'"
                :src="medalZz2"
              >
            </span>
          </div>
          <v-row class="mt-2">
            <template v-for="projectInfo in newProjectInfo">
              <v-col
                v-if="projectInfo.value && projectInfo.code !== 'tags' && projectInfo.type !== 'files' && projectInfo.code !== 'name'"
                :key="projectInfo.code"
                cols="12"
              >
                <b class="red--text text--darken-4">
                  {{ projectInfo.name }}：
                </b>
                <span>
                  {{ projectInfo.value }}
                </span>
              </v-col>
              <v-col
                v-if="projectInfo.code === 'tags' && projectInfo.value"
                :key="projectInfo.code"
              >
                <tags :tags-data="projectInfo" />
              </v-col>
              <v-col
                v-if="projectInfo.type === 'files' && projectInfo.value"
                :key="projectInfo.code"
              >
                <files :file="projectInfo" />
              </v-col>
            </template>
          </v-row>
          <examine
            v-if="formId ===tjFormId || formId ===wsFormId "
            :form-id="formId"
            :project-id="id"
            :user-id="userId"
            @pushInit="pushInit()"
          />
          <!-- 市级推送到区县 -->
          <v-row v-if="isMarket && formId ===wsFormId">
            <v-col
              cols="12"
              class="text-center"
            >
              <v-btn
                :large="isXs ? false : true"
                color="success"
                @click="pushMove"
              >
                推送
              </v-btn>
            </v-col>
          </v-row>
          <!-- 市推送report-id传推送表项目的 -->
          <org-pushtrack
            v-if="isMarket && formId ===wsFormId"
            ref="OrgPushtrack"
            :parent-id="$route.query.dynamicId"
            :allparent-id="id"
            :form-id="formId"
            @OrgPushInit="OrgPushInit()"
          />
        </v-card>
        <!-- 除区县 -->
        <planmove
          v-if="formId ===wsFormId && !(orgQX && !isMarket)"
          ref="planmove"
          :report-id="id"
          :form-id="formId"
        />
        <advancemove
          v-if="formId ===wsFormId || formId ===tjFormId"
          ref="Advancemove"
          :report-id="id"
          :form-id="formId"
          @pushInit="pushInit()"
        />
      </v-col>
      <v-col
        md="4"
        sm="12"
      >
        <group
          v-if="false"
          :user-id="userId"
          :report-id="id"
        />
        <waiter
          :user-id="userId"
          :report-id="id"
          :form-id="formId"
        />
        <!-- <processes
          :report-id="id"
          :form-id="formId"
        /> -->
        <!-- 外商推送列表（除市区县） -->
        <pushmove
          v-if="!orgQX && formId ===wsFormId"
          ref="pushmove"
          :report-id="id"
          :form-id="formId"
          :isdynamic="isdynamic"
          :detail-list="detailList"
          @pushInit="pushInit()"
        />
        <!-- 市推送列表 与外商相同（市推送report-id传推送表项目的） -->
        <pushmove
          v-if="isMarket && formId ===wsFormId"
          ref="orgpushmove"
          :report-id="$route.query.dynamicId"
          :form-id="formId"
          :isdynamic="isdynamic"
          :detail-list="detailList"
          is-org="是"
        />
        <!-- 重点对外推介推送列表(除代表处) -->
        <pushmove2
          v-if="!isDeputy && formId ===tjFormId"
          ref="pushmove2"
          :report-id="id"
          :form-id="formId"
          :isdynamic="isdynamic"
          :detail-list="detailList"
          @pushInit="pushInit()"
        />
        <!-- 项目动态 -->
        <!-- <dynamic
          v-if="!orgQX && formId ===wsFormId"
          ref="Dynamic"
          :report-id="id"
          :form-id="formId"
        /> -->
        <!-- 外商+对外推介推送 -->
        <pushtrack
          v-if="(isHshow && formId ===wsFormId) || (!isDeputy && formId ===tjFormId)"
          ref="pushtrack"
          :parent-id="id"
          :form-id="formId"
          @pushInit="pushInit()"
        />
        <!-- 项目跟踪小组 -->
        <!--  <planright
          v-if="!orgQX &&formId ===wsFormId"
          ref="Planright"
          :report-id="id"
          :form-id="formId"
        /> -->
        <log
          :report-id="id"
          :form-id="formId"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-model="imageShow"
      max-width="700"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ photoName }}
        </v-card-title>
        <v-card-text>
          <v-img
            :src="bigPhoto"
            aspect-ratio="1"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="imageShow = false"
          >
            关闭
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="py-3" />
    <audioBox ref="audioBox" />
    <v-row v-if="islnglat">
      <div
        id="openlayer"
        class="openlayer"
      />
    </v-row>
  </v-container>
</template>
<script>
  import pdf from '../../assets/pdf.png'
  import els from '../../assets/excel.png'
  import ppt from '../../assets/ppt.png'
  import doc from '../../assets/word.png'
  import zip from '../../assets/zip.png'
  import xmzt1 from '../../assets/待完善.png'
  import xmzt2 from '../../assets/待推送.png'
  import xmzt3 from '../../assets/待确认.png'
  import medalAdopt from '../../assets/adopt.png'
  import medalReject from '../../assets/reject.png'
  import medalAdopt2 from '../../assets/已推送.png'
  import medalReject2 from '../../assets/未推送.png'
  import medalZz2 from '../../assets/tszz.png'
  import mapIcon from '../../assets/map_icon.png'
  import auditDoubts from '../../api/auditDoubts'
  import details from '../../utils/details'
  export default {
    components: {
      waiter: () => import('./component/Waiter'),
      processes: () => import('./component/Processes'),
      pushmove: () => import('./component/Pushmove'),
      pushmove2: () => import('./component/Pushmove2'),
      planmove: () => import('./component/Planmove'),
      Planright: () => import('./component/Planright'),
      Advancemove: () => import('./component/Advancemove'),
      pushtrack: () => import('./component/Pushtrack'),
      OrgPushtrack: () => import('./component/OrgPushtrack'),
      log: () => import('./component/Logs'),
      dynamic: () => import('./component/Dynamic'),
      examine: () => import('./component/Examine'),
      audioBox: () => import('../../components/base/Audio'),
      group: () => import('./component/Group'),
      tags: () => import('./component/Tags.vue'),
      files: () => import('./component/Files.vue'),
    },
    data: () => ({
      pdf: pdf,
      xmzt1: xmzt1,
      xmzt2: xmzt2,
      xmzt3: xmzt3,
      medalAdopt: medalAdopt,
      medalReject: medalReject,
      medalAdopt2: medalAdopt2,
      medalReject2: medalReject2,
      medalZz2: medalZz2,
      els: els,
      ppt: ppt,
      doc: doc,
      zip: zip,
      mapIcon: mapIcon,
      active: [],
      isFollow: false,
      open: [],
      ismeRole: false,
      projectStatus2: '',
      projectStatusLight: '',
      rejectMessage: '',
      imageShow: false,
      photoName: '',
      formId: '',
      id: '',
      projectName: '',
      stage: 0,
      // 步骤节点
      stage1: 0,
      stage1s: 0,
      stage2: 0,
      stage2s: 0,
      // 步骤节点的状态（10通过，0审核中，-5驳回）
      stageStatus1: 10,
      stageStatus2: 10,
      isComplete: false,
      stageName: '',
      projectInfo: [],
      newProjectInfo: [],
      projectInfoKey: [],
      dynamics: [],
      bigPhoto: '',
      message: {
        content: '',
        type: '工作进展',
        attachment: '',
        bindId: '',
        lat: '0',
        lng: '0',
      },
      files: [],
      imagesList: [],
      userId: '',
      e6: 2,
      processesActiveIndex: 1,
      projectState: null,
      projectisPush: '',
      lnglat: [],
      olmap: null,
      OpenLayers: null,
      graphicLayer: null,
      vectorLayer: null,
      islnglat: true,
      isdynamic: '',
      detailList: {},
      orgQX: false,
      isHshow: false,
      isDeputy: false,
      isAdmin: false,
      stageStatus: '',
      isMarket: false,
      // 外商
      wsFormId: 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
      // 推介
      tjFormId: 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe',
      // 客户
      kuFormId: 'ad326bff-dcd0-4610-95c2-3a2d6c4f165a',
      searchParame: {
        page: 0,
        size: 5,
        formId: 'cf45fb6b-8c8f-4e1e-b9f8-1b2b774fea2b',
        // _from: 'grant',
      },
      isCjTs: false,
      // 推介和外商的审核人员
      isEdit: false,
      processesList: [],
      processesList2: [{ name: '已推送', value: '已推送' }],
      processesList2s: [],
      processesList1: [{ name: '预审', level: 1, isicon: false, icon: '', color: '#9E9E9E' }, { name: '初审', level: 2, isicon: false, icon: '', color: '#9E9E9E' }, { name: '终审', level: 3, isicon: false, icon: '', color: '#9E9E9E' }],
      processesList1s: [{ name: '待推送', level: 1, isicon: false, icon: '', color: '#9E9E9E' }, { name: '已推送', level: 2, isicon: false, icon: '', color: '#9E9E9E' }],
      // processesList2: [{ name: '市审核', level: 1, isicon: false, icon: '', color: '#9E9E9E' }, { name: '省预审', level: 2, isicon: false, icon: '', color: '#9E9E9E' }, { name: '省初审', level: 3, isicon: false, icon: '', color: '#9E9E9E' }, { name: '省终审', level: 4, isicon: false, icon: '', color: '#9E9E9E' }],
      //  processesList2s: [{ name: '待推送', level: 5, isicon: false, icon: '', color: '#9E9E9E' }, { name: '已推送', level: 6, isicon: false, icon: '', color: '#9E9E9E' }],
      isXs: false,
    }),
    watch: {
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    async created () {
      this.init()
    },
    mounted () {
      this.openlayer()
      // 中止图标
      if (this.orgQX) {
        this.isZimg()
      }
    },
    methods: {
      async init () {
        this.isEdit = localStorage.isEdit === 'true' || localStorage.isEdit === true
        this.orgQX = localStorage.orgQX === 'true' || localStorage.orgQX === true
        this.isHshow = localStorage.isHshow === 'true' || localStorage.isHshow === true
        this.isDeputy = localStorage.isDeputy === 'true' || localStorage.isDeputy === true
        this.isAdmin = localStorage.isAdmin === 'true' || localStorage.isAdmin === true
        this.isadvance = JSON.parse(localStorage.getItem('systemConfig')).advance
        this.formId = this.$route.params.formId
        this.id = this.$route.params.id
        this.userlevel = localStorage.level
        this.isMarket = localStorage.isMarket === 'true'
        if (this.$route.query.isdynamic) {
          this.isdynamic = this.$route.query.isdynamic
        }
        await this.isStage(this.formId)
        await this.MergeData()
        await this.getDynamic()
        await this.getfollowList()
        if (this.orgQX) {
          this.getProjectList()
        } else {
          // this.isCjTs = true
        }

        await this.getProcesses()
      },
      //! 审核权限的判断与按钮的展示
      async getProcesses () {
        // 初始化
        this.stage1 = 0
        this.processesList2s = []
        this.processesList2.map(val => {
          this.$set(val, 'isicon', false)
        })
        // 审核流程和推送流程分开显示
        // 推送流程
        var list = []
        console.log('this.projectStatus2', this.projectStatus2);
        if (this.formId === this.wsFormId) {
          list = await this.getSelectDictData(
            'ba99ae56-7c5a-4fc0-98f0-93ea65c88878',
          );
        } else if (this.formId === this.tjFormId) {
          list = await this.getSelectDictData(
            'eccd42d9-a0e3-41b6-bb29-05142e0753d2',
          );
        }
        this.processesList2s = this.processesList2.concat(list)
        for (let inx = 0; inx < this.processesList2s.length; inx++) {
          if (this.projectStatus2 === this.processesList2s[inx].name) {
            this.stage1 = inx + 1
            this.$set(this.processesList2s[inx], 'isicon', true)
            this.$set(this.processesList2s[inx], 'color', 'green')
            this.$set(this.processesList2s[inx], 'icon', 'mdi-check-bold')
            if (this.projectStatusLight) {
              // 防止最后一个
              if (this.processesList2s[inx + 1]) {
                this.$set(this.processesList2s[inx], 'isicon', false)
                this.$set(this.processesList2s[inx + 1], 'isicon', true)
                this.$set(this.processesList2s[inx + 1], 'color', this.timeColor2(this.projectStatusLight))
                this.$set(this.processesList2s[inx + 1], 'icon', 'mdi-lightbulb')
              } else {
                this.$set(this.processesList2s[inx], 'isicon', true)
                this.$set(this.processesList2s[inx], 'color', this.timeColor2(this.projectStatusLight))
                this.$set(this.processesList2s[inx], 'icon', 'mdi-lightbulb')
              }
            }
          }
        }
        console.log('this.processesList2s', this.processesList2s);

        // 审核流程
        // else {
        //  [{ name: '预审', level: 1, isicon: false, icon: '', color: '#9E9E9E' }, { name: '初审', level: 2, isicon: false, icon: '', color: '#9E9E9E' }, { name: '终审', level: 3, isicon: false, icon: '', color: '#9E9E9E' }],

        const processesData = await this.$axios.get(`/reports/${this.id}/processes`)
        const processes = processesData.data.data
        for (let inx = 0; inx < processes.length; inx++) {
          this.$set(processes[inx], 'isicon', false)
          // 判断节点
          if (processes[inx].active === 1 && (processes[inx].status === 0 || processes[inx].status === -5)) {
            this.stage1 = inx + 1
            this.stageStatus1 = processes[inx].status
            if (processes[inx].status === 10) {
              this.$set(processes[inx], 'color', 'green')
              this.$set(processes[inx], 'icon', 'mdi-check-bold')
              this.$set(processes[inx], 'isicon', true)
            } else if (processes[inx].status === -5) {
              this.$set(processes[inx], 'color', 'red')
              this.$set(processes[inx], 'icon', 'mdi-close-thick')
              this.$set(processes[inx], 'isicon', true)
            } else {
              this.$set(processes[inx], 'color', 'blue')
              this.$set(processes[inx], 'icon', 'mdi-clock-outline')
              this.$set(processes[inx], 'isicon', true)
            }
            console.log('this.projectStatus2', this.projectStatus2);

            if (processes[inx].status === 0 && processes[inx].auditEndAt) {
              console.log('999');

              this.$set(processes[inx], 'icon', 'mdi-lightbulb')
              this.$set(processes[inx], 'isicon', true)
              this.$set(processes[inx], 'color', this.timeColor(processes[inx].auditEndAt))
            }
          }
          // 对于之前一些审核流程没有name
          if (processes[inx].roleValue === 'ROLE_TZCJZX') {
            if (this.formId === this.wsFormId) {
              this.$set(processes[inx], 'name', '预审')
            } else {
              this.$set(processes[inx], 'name', '省预审')
            }
          } else if (processes[inx].roleValue === 'ROLE_TZCJCNBYJ') {
            if (this.formId === this.wsFormId) {
              this.$set(processes[inx], 'name', '初审')
            } else {
              this.$set(processes[inx], 'name', '省初审')
            }
          } else if (processes[inx].roleValue === 'ROLE_TZCJCFZRSHYJ') {
            if (this.formId === this.wsFormId) {
              this.$set(processes[inx], 'name', '终审')
            } else {
              this.$set(processes[inx], 'name', '省终审')
            }
          }
        }
        // 没有项目状态和位置点，就是审核状态最后一步审核通过的
        if (!this.projectStatus2 && !this.stage1) {
          this.stage1 = processes.length
          this.$set(processes[processes.length - 1], 'isicon', true)
          this.$set(processes[processes.length - 1], 'color', 'green')
          this.$set(processes[processes.length - 1], 'icon', 'mdi-check-bold')
        }
        console.log('processes', processes);

        this.processesList = processes
      },
      // async getProcesses () {
      //   // 初始化
      //   this.stage1 = 0
      //   this.stage2 = 0
      //   this.stage1s = 0
      //   this.stage2s = 0
      //   this.processesList1.map(val => {
      //     this.$set(val, 'isicon', false)
      //   })
      //   this.processesList1s.map(val => {
      //     this.$set(val, 'isicon', false)
      //   })
      //   this.processesList2.map(val => {
      //     this.$set(val, 'isicon', false)
      //   })
      //   this.processesList2s.map(val => {
      //     this.$set(val, 'isicon', false)
      //   })
      //   // 审核流程和推送流程分开显示
      //   // 推送流程
      //   if (this.projectisPush) {
      //     if (this.formId === this.wsFormId) {
      //       this.processesList1s[1].isicon = true
      //       this.processesList1s[1].color = 'green'
      //       this.processesList1s[1].icon = 'mdi-check-bold'
      //       this.stage1s = 2
      //     } else if (this.formId === this.tjFormId) {
      //       this.processesList2s[1].isicon = true
      //       this.processesList2s[1].color = 'green'
      //       this.processesList2s[1].icon = 'mdi-check-bold'
      //       this.stage2s = 2
      //     }
      //   } else if (this.projectState === 10) {
      //     if (this.formId === this.wsFormId) {
      //       this.processesList1s[0].isicon = true
      //       this.processesList1s[0].color = 'blue'
      //       this.processesList1s[0].icon = 'mdi-clock-outline'
      //       this.stage1s = 1
      //     } else if (this.formId === this.tjFormId) {
      //       this.processesList2s[0].isicon = true
      //       this.processesList2s[0].color = 'blue'
      //       this.processesList2s[0].icon = 'mdi-clock-outline'
      //       this.stage2s = 1
      //     }
      //   }

      //   // 审核流程
      //   // else {
      //   const processesData = await this.$axios.get(`/reports/${this.id}/processes`)
      //   const processes = processesData.data.data
      //   if (this.formId === this.wsFormId) {
      //     for (const p of processes) {
      //       if (p.active === 1 && (p.status === 0 || p.status === -5)) {
      //         if (p.roleValue === 'ROLE_TZCJZX') {
      //           this.stage1 = 1
      //           this.stageStatus1 = p.status
      //         } else if (p.roleValue === 'ROLE_TZCJCNBYJ') {
      //           this.stage1 = 2
      //           this.stageStatus1 = p.status
      //         } else if (p.roleValue === 'ROLE_TZCJCFZRSHYJ') {
      //           this.stage1 = 3
      //           this.stageStatus1 = p.status
      //         }
      //       }
      //     }
      //   } else if (this.formId === this.tjFormId) {
      //     for (const p of processes) {
      //       if (p.active === 1 && (p.status === 0 || p.status === -5)) {
      //         if (p.roleValue.includes('ORG_37') || p.roleValue === 'ROLE_SHIJI') {
      //           this.stage2 = 1
      //           this.stageStatus2 = p.status
      //         } else if (p.roleValue === 'ROLE_TZCJZX') {
      //           this.stage2 = 2
      //           this.stageStatus2 = p.status
      //         } else if (p.roleValue === 'ROLE_TZCJCNBYJ') {
      //           this.stage2 = 3
      //           this.stageStatus2 = p.status
      //         } else if (p.roleValue === 'ROLE_TZCJCFZRSHYJ') {
      //           this.stage2 = 4
      //           this.stageStatus2 = p.status
      //         }
      //       }
      //     }
      //   }
      //   // this.processesList = processes
      //   console.log('processes', processes);
      //   // }
      //   this.processesList1.map(val => {
      //     delete val.isicon
      //     if (val.level === this.stage1) {
      //       this.$set(val, 'isicon', true)
      //       if (this.stageStatus1 === 10) {
      //         this.$set(val, 'color', 'green')
      //         this.$set(val, 'icon', 'mdi-check-bold')
      //       } else if (this.stageStatus1 === -5) {
      //         this.$set(val, 'color', 'red')
      //         this.$set(val, 'icon', 'mdi-close-thick')
      //       } else {
      //         this.$set(val, 'color', 'blue')
      //         this.$set(val, 'icon', 'mdi-clock-outline')
      //       }
      //     }
      //   })
      //   this.processesList2.map(val => {
      //     delete val.isicon
      //     if (val.level === this.stage2) {
      //       this.$set(val, 'isicon', true)
      //       if (this.stageStatus2 === 10) {
      //         this.$set(val, 'color', 'green')
      //         this.$set(val, 'icon', 'mdi-check-bold')
      //       } else if (this.stageStatus2 === -5) {
      //         this.$set(val, 'color', 'red')
      //         this.$set(val, 'icon', 'mdi-close-thick')
      //       } else {
      //         this.$set(val, 'color', 'blue')
      //         this.$set(val, 'icon', 'mdi-clock-outline')
      //       }
      //     }
      //   })
      // },
      getXmzt (projectStatus2, projectState) {
        if (projectStatus2) {
          try {
            return require(`../../assets/${projectStatus2}.png`)
          } catch (error) {
            return require('../../assets/待确认.png')
          }
        } else {
          let name = '待确认'
          if (projectState === 10) {
            name = '已确认'
          } else if (projectState === -5) {
            name = '待完善'
          }

          try {
            return require(`../../assets/${name}.png`)
          } catch (error) {
            return require('../../assets/待确认.png')
          }
        }
      },
      timeColor (time) {
        const nowTime = this.$moment(new Date()).format('YYYY-MM-DD')
        const oldTime = this.$moment(time).format('YYYY-MM-DD')
        console.log('oldTime', oldTime, 'nowTime', nowTime, 'day', this.$moment(nowTime).diff(oldTime, 'day'));
        const timeC = this.$moment(nowTime).diff(oldTime, 'day')
        let overtimeC = '#20c954'
        if (timeC > 0) {
          overtimeC = 'red'
        } else if (timeC < 0) {
          overtimeC = '#20c954'
        } else {
          overtimeC = '#FF9900'
        }
        return overtimeC
      },
      timeColor2 (projectStatusLight) {
        if (projectStatusLight === '绿灯') {
          return '#20c954'
        } else if (projectStatusLight === '红灯') {
          return 'red'
        } else if (projectStatusLight === '黄灯') {
          return '#FF9900'
        } else {
          return '#20c954'
        }
      },
      async getProjectList () { // 获取动态列表
        const searchParame = JSON.parse(JSON.stringify(this.searchParame))
        searchParame.parentID = this.id
        await this.$axios.get('/reports/query', {
          params: searchParame, // 搜索参数对象
        })
          .then((response) => {
            const resData = response.data.data
            const projectList = JSON.parse(JSON.stringify(resData.content))
            const cjtsLiat = projectList[0]

            // 待处理，中止，拒绝不显示承接方案 项目推进情况
            if (this.orgQX && cjtsLiat) {
              if (cjtsLiat.data.stageStatus === '中止' || cjtsLiat.data.stageStatus === '拒绝' || cjtsLiat.data.stageStatus === '待处理') {
                this.isCjTs = false
              } else {
                this.isCjTs = true
              }
            }
          })
          .catch((error) => {
            console.log(error)
          })
      },
      // 获取选择列表数据
      async getSelectDictData (dictId) {
        const select = [];
        await this.$axios.get(`/dictionaries/${dictId}`).then((res) => {
          const resData = res.data.data;
          resData.map((val) => {
            select.push({
              value: val.code,
              name: val.name,
            });
          });
        });
        return select;
      },
      async isZimg () {
        const resp = await this.$axios.get('/reports/' + this.$route.query.dynamicId + '/detail')
        if (resp.data.code === 200) {
          this.stageStatus = resp.data.data.data.stageStatus
        }
      },
      // 市区推送列表
      OrgPushInit () {
        this.$refs.orgpushmove.getchild()
      },
      // 推送后更新
      async pushInit () {
        setTimeout(async () => {
          await this.init()
          this.$refs.pushtrack.getOpinion()
          // this.$refs.Dynamic.open()
          if (this.formId === this.wsFormId) {
            // 承接方案
            this.$refs.planmove.searchChild()
            // 外商推送列表
            this.$refs.pushmove.getchild()
          } else if (this.formId === this.tjFormId) {
            // 推介推送列表
            this.$refs.pushmove2.getchild()
          }
        }, 1000);
      },
      pushMove () {
        this.$refs.OrgPushtrack.open(true)
      },
      openlayer () {
        const OpenLayers = window.OpenLayers
        const SDRasterLayer = window.SDRasterLayer
        const SDRSAnnoLayer = window.SDRSAnnoLayer
        this.olmap = new OpenLayers.Map('openlayer', {
          allOverlays: true,
          numZoomLevels: 19,
          displayProjection: 'EPSG:4490',
        })
        // 影像图层初始化
        var sdimgLayer = new SDRasterLayer()
        // 注记图层初始化
        var sdannoLayer = new SDRSAnnoLayer()
        this.olmap.addLayer(sdimgLayer)
        this.olmap.addLayer(sdannoLayer)
        // this.setMarker(117.05910, 36.68027)
        this.olmap.setCenter(new OpenLayers.LonLat(117.05910, 36.68027), 16)
        // 创建矢量图层
        this.graphicLayer = new OpenLayers.Layer.Vector('graphicLayer', { style: OpenLayers.Util.extend({}, OpenLayers.Feature.Vector.style['default']) })
        this.olmap.addLayer(this.graphicLayer)
        // 创建图形图层
        this.vectorLayer = new OpenLayers.Layer.Vector('graphicLayer', { style: OpenLayers.Util.extend({}, OpenLayers.Feature.Vector.style['default']) })
        this.olmap.addLayer(this.vectorLayer)
      },
      setMarker (lng, lat) {
        const OpenLayers = window.OpenLayers
        var pt = new OpenLayers.Geometry.Point(lng, lat)
        var style = {
          externalGraphic: mapIcon,
          graphicWidth: 50,
          graphicHeight: 50,
        }
        var feature = new OpenLayers.Feature.Vector(pt, null, style)
        this.graphicLayer.addFeatures([feature])
        this.olmap.setCenter(new OpenLayers.LonLat(lng, lat), 16)
      },
      // 编辑项目按钮
      editProject (formId, id) {
        this.$refs.audioBox.audioPlay('click')
        this.$router.push({
          path: '/report',
          query: {
            formId: formId,
            id: id,
          },
        })
      },
      isHttp (value) {
        if (value) {
          if (value.includes('http')) {
            return true
          } else {
            return false
          }
        } else {
          return value
        }
      },
      isStage (formId) {
        const forms = JSON.parse(localStorage.newForms)
        forms.map((val, index) => {
          if (val.id === formId) {
            this.stage = index + 1
          }
        })
      },
      tagsList (val) {
        return val.split(',')
      },
      //! 获取项目信息
      async getProjectInfo () {
        const resData = await this.$axios.get('/reports/' + this.id + '/detail')
        if (this.orgQX && this.formId === this.wsFormId) {
          delete resData.data.data.data.inferred
          delete resData.data.data.data.kfqHandle
          delete resData.data.data.data.tzcHandle
          delete resData.data.data.data.tzcfzrHandle
          delete resData.data.data.data.isPush1
          delete resData.data.data.data.pushReason
        }

        // 赋值项目状态
        this.projectState = resData.data.data.status
        // 赋值项目推送状态
        this.projectisPush = resData.data.data.data.isPush
        // 获取userID
        this.userId = resData.data.data.userId
        // 获取项目名称
        this.projectName = resData.data.data.name
        // 项目状态字段
        this.projectStatus2 = resData.data.data.data.projectStatus2
        // 项目推进亮灯字段
        this.projectStatusLight = resData.data.data.data.projectStatusLight
        this.detailList = resData.data.data
        return resData.data.data
      },
      //! 获取表单数据，用于提取字段名称
      async getDataList () {
        return this.$axios.get('/forms/' + this.formId + '/fields')
      },
      //! 合并数据
      async MergeData () {
        // 获取项目信息
        const projectInfoObj = await this.getProjectInfo()
        // 获取表单数据
        const dataListObj = await this.getDataList()
        const projectInfo = projectInfoObj.data

        // 获取坐标
        if (projectInfo.lnglat) {
          const newLnglat = projectInfo.lnglat.includes('["')
          if (newLnglat) {
            const lnglatArr = JSON.parse(projectInfo.lnglat)
            lnglatArr.map(val => {
              const lnglat = val.split(',')
              console.log(lnglat[0], lnglat[1])
              this.setMarker(lnglat[0], lnglat[1])
            })
          } else {
            const lnglatArr = projectInfo.lnglat.split(',')
            this.setMarker(lnglatArr[0], lnglatArr[1])
          }
        } else {
          this.islnglat = false
        }
        // 合并和兑换数据
        this.newProjectInfo = await details.detailsData(dataListObj, projectInfoObj)
      },
      //! 获取项目动态
      getDynamic (oldId) {
        this.$axios.get('/projects/' + this.id + '/comments')
          .then((res) => {
            this.dynamics = res.data.data.reverse()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //! 提交留言
      async submitMessage () {
        const formData = new window.FormData()
        formData.append('file', this.files[0])
        const resFile = await this.$axios.post('/files', formData)
        this.message.bindId = this.id
        this.message.attachment = resFile.data.data.url
        this.$axios.post('/comments', this.message)
          .then(res => {
            console.log(res)
          })
          .catch(err => {
            console.log(err)
          })
        this.getDynamic()
      },
      is_weixin () {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.indexOf('micromessenger') !== -1) {
          console.log('微信浏览器');
          return true;
        } else {
          console.log('不是微信浏览器');
          return false;
        }
      },
      // 导出项目
      exportTable () {
        if (this.is_weixin()) {
          this.$swal({
            title: '点击右上角选择在浏览器中打开!',
            icon: 'warning',
            showConfirmButton: false,
            timer: 1500,
          })
        } else {
          this.$refs.audioBox.audioPlay('click')
          this.$axios.get('reports/' + this.id + '/word')
            .then(res => {
              const resData = res.config.url
              this.exportFile(resData, this.projectName)
            })
            .catch(err => {
              console.log(err)
            })
        }
      },
      // 导出文件
      async exportFile (url, name) {
        const data = await this.$axios.get(url, {
          responseType: 'blob',
        })
        const link = document.createElement('a')
        // link.style.display = 'none'
        link.href = window.URL ? window.URL.createObjectURL(new Blob([data.data], { type: 'application/vnd.msword' })) : window.webkitURL.createObjectURL(new Blob([data.data], { type: 'application/vnd.msword' }))
        link.setAttribute('download', name + '.doc')
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
      // 获取关注的列表
      async getfollowList () {
        const followList = []
        await this.$axios.get('/favorites/report')
          .then(res => {
            const followListData = res.data.data.content
            followListData.map(function (val) {
              followList.push(val.modelId)
            })
          })
          .catch(err => {
            console.log(err)
          })
        if (followList.includes(this.id)) {
          this.isFollow = true
        } else {
          this.isFollow = false
        }
      },
      // 取消关注
      delDollowBtn () {
        this.$axios.delete('/favorites/report/' + this.id)
          .then(res => {
            this.getfollowList()
          })
          .catch(err => {
            console.log(err)
          })
      },
      // 添加关注
      followBtn () {
        this.$axios.put('/favorites/report/' + this.id)
          .then(res => {
            this.getfollowList()
          })
          .catch(err => {
            console.log(err)
          })
      },
      // 格式化行政区为名称
      async formatAreaCode (code) {
        const areaCode = code.split(',')
        const cityTreeData = await this.$axios.get(`/districts/${areaCode[areaCode.length - 1]}`)
        const cityTree = cityTreeData.data.data.name
        return cityTree
      },
      // 格式化资金来源
      async formatMoneyFrom (code) {
        const countryTreeData = await this.$axios.get('/dictionaries/country')
        const countryTree = countryTreeData.data.data
        let countryName = ''
        countryTree.map(val => {
          if (val.code === code) {
            countryName = val.name
          }
        })
        return countryName
      },
      downFile (url, fileName, suffix) {
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
      auditDoubts () {
        auditDoubts.addAuditDoubts({
          reportId: this.id,
          reason: '项目存疑原因',
        })
      },
    },
  }
</script>
<style scoped>
@import url('./styles/reportDetails.scss');
.mapDiv {
  width: 100%;
  height: 300px;
}
.openlayer {
  width: 100%;
  height: 300px;
}
</style>
